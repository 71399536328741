import React from "react";
import { SEO, PageLayout, HeroElement, Content } from "@bluefin/components";
import { Grid, Image } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getPageHeroImage } from "../utils/utils";

export default class AboutPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getPageHeroImage({
            options: fishermanBusinessWebsitePage.components,
          })}
        />
        <PageLayout
          hero={
            <HeroElement
              header={"About Josephine A. Doyle"}
              tagline={""}
              images={getPageHeroImage({
                options: fishermanBusinessWebsitePage.components,
              })}
              ctas={[]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
            />
          }
        >
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"left"}
          >
            <Grid.Column width={16}>
              <Content container={true}>
                <Content.Markup isMarkdown={false}>
                  <Grid stackable={true} padded={true} columns={"2"}>
                    <Grid.Column width={"6"}>
                      <Image
                        src={
                          "https://fisherman.gumlet.io/public/doylejosephinea/JosephineDoyle-7095e25f16e377a465bfbf579ce5c533.jpeg"
                        }
                        centered={true}
                        size={"medium"}
                      />
                    </Grid.Column>
                    <Grid.Column width={"10"}>
                      <h1>About Attorney Josephine A. Doyle</h1>
                      <p>
                        Josephine attended St. John’s University School of Law,
                        graduating in 1987. She is engaged in the practice of
                        law with an emphasis on Elder Law, Trusts and Estates,
                        Real Estate and Guardianships. She has served as a Small
                        Claims Assessment Review Officer for Nassau County and
                        Small Claims Hearing Officer for Queens County. She has
                        also represented a major airline in labor relations
                        matters.
                      </p>
                      <p>
                        <span>
                          If you have any questions or comments, or would like
                          to schedule a consultation, please contact me at
                          516-616-7770 or by email:
                        </span>
                        <a
                          target={"_blank"}
                          href={"mailto:attorneydoyle@aol.com"}
                        >
                          attorneydoyle@aol.com
                        </a>
                      </p>
                      <p>
                        This is Attorney Advertising. The material in this web
                        site is for informational purposes only; visitors should
                        not rely on the information as advice or as a
                        consultation, but should consult a lawyer about their
                        specific legal issues. The content of the site in no way
                        creates an attorney-client relationship.
                      </p>
                      <p>
                        Serving all of New York including, but not limited to,
                        Nassau County, Suffolk County, Kings County, and Queens
                        County.
                      </p>
                    </Grid.Column>
                  </Grid>
                </Content.Markup>
              </Content>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(title: { eq: "About" }) {
      title
      components {
        fastId
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
